import axios from "axios";

export const documentService = {

    methods: {
        getAllDocuments(baseApiUrl) {
          
            return axios.get(`${baseApiUrl}/api/v1/documents`, {withCredentials: true})
        },
        getLink(id, baseApiUrl) {
           
            
            var lien = {
                'documentId': id,
                'joursValide': 3,
                'password': '',
                'utilisationRestantes': 3,
                'utilisationMax': 3,
                'contact': ''
            };
            return axios.post(`${baseApiUrl}/api/v1/liens`, lien, {withCredentials: true});
        }
    }
}

