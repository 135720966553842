<template>
  <div>
    <div @click="emitDocument()">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { categoryService } from "../services/categoryService";
import { ssoService } from "../services/ssoService";
import axios from "axios";

export default {
  data() {
    return {
      identiteToString: "",
      domicileToString: "",
      nationaleToString: "",
      errorToString: "",
      authentifie: false,
      popUpConnexionInInterval: false,
    };
  },
  name: "cnaCategory",
  props: {
    tag: { type: String, default: "PLUGIN DNA CATEGORY" },
    tokenAccess: { type: String, default: "" },
    baseApiUrl: { type: String, default: "http://localhost" },
    mode: { type: String, default: "CATEGORY_DOCUMENT" },
    nomDocument: { type: String, default: "" },
  },
  mixins: [categoryService, ssoService],
  methods: {
    emitDocument: async function() {
      axios.get(`${this.baseApiUrl}/api/v1/types/${this.nomDocument}/documents`, {withCredentials: true})
        .then(document => {
          this.$emit("document", document.data);
        });
    }
  },
  mounted: function () {
    this.checkSSO(this.baseApiUrl)
        .then(() => {
          this.authentifie = true;
        });

    this.emitDocument();
  },
};
</script>

<style scoped>
</style>