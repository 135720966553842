//import axios from "axios";
//import jsonCategoryJustificatifIdentite from "../assets/bouchonGetCategory/getCategoryJustificatifDomicile.json";

export const categoryService = {

    methods: {
        /**getCategory(categoryId, baseApiUrl, tokenAccess) {
            var config = {Authorization: `Bearer ${tokenAccess}`};
            return axios.get(`${baseApiUrl}/api/v1/administres/{{user}}/documents/${categoryId}`, {headers: config})
        }*/
    }
}