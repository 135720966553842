<template>
  <div class="fontStyle">
    <b-modal
      size="xl"
      static
      scrollable
      v-model="modalShow"
      header-bg-variant="primary"
      header-text-variant="light"
      ok-title="Fermer"
    >
      <!--            HEADER MODAL-->
      <div slot="modal-title">
        <b-icon-folder-fill font-scale="1" />
        Mes Documents
      </div>

<!--      <div @click="">-->
<!--        <slot></slot>-->
<!--      </div>-->
      <!--            BOUTON GET LINK & LINK-->
      <template slot="modal-footer">
        <b-col>
          <b-row class="justify-content-md-center">
            <b-input-group v-if="link !== ''" prepend="LIEN :" v-model="link">
              <b-form-input id="linkDoc" v-model="link" disabled />
              <b-input-group-append>
                <b-button variant="primary" @click="copyToClipboard(link)">
                  <b-icon-subtract />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-row>
          <b-row class="justify-content-md-center">
            <b-button
              v-if="selected.length != 0"
              variant="primary"
              @click="serviceGetLink()"
            >
              GÉNÉRER LE LIEN
            </b-button>
          </b-row>
        </b-col>
      </template>

      <!--            TABLE DOCUMENTS-->
      <b-table
        fixed
        class="pa-0 ma-0 text-center"
        :items="documents"
        :fields="fieldsDocs"
        selectable
        select-mode="single"
        @row-selected="onRowSelected"
      >
        <!--                TRONQUAGE DES NOMS-->
        <template v-slot:cell(nom)="row">
          <span>{{ row.item.nom | truncate(15, "...") }}</span>
        </template>

        <!--                DATE D'EXPIRATION-->
        <template v-slot:cell(expirationDate)="row">
          <span>{{ formatDate(row.item.expirationDate) }}</span>
        </template>

        <!--                STATUS-->
        <template v-slot:cell(status)="row">
          <b-badge v-if="row.item.status === 'PERIME'" variant="danger">
            PÉRIMÉ
          </b-badge>
          <b-badge v-if="row.item.status === 'EN_COURS'" variant="warning">
            EN COURS
          </b-badge>
          <b-badge v-if="row.item.status === 'CERTIFIE'" variant="success">
            CERTIFIÉ
          </b-badge>
          <b-badge v-if="row.item.status === 'REFUSE'" variant="danger">
            REFUSÉ
          </b-badge>
          <b-badge
            v-if="row.item.status === 'CERTIFIE_EXPIRE'"
            variant="danger"
          >
            EXPIRÉ
          </b-badge>
        </template>

        <!--                METADONNEES BOUTON-->
        <template v-slot:cell(metaDonnees)="row">
          <b-button
            class="pa-0 ma-0"
            pill
            variant="primary"
            size="sm"
            @click="openModalInfo(row.item.metaDonnees)"
          >
            <b-icon
              icon="info-circle-fill"
              class="my-1 pa-0"
              size="sm"
            ></b-icon>
          </b-button>
        </template>
      </b-table>

      <!--            METADONNEES MODAL-->
      <b-modal static v-model="modalInfo">
        <template v-slot:modal-footer>
          <b-col>
            <b-row class="justify-content-md-center">
              <b-button variant="primary" @click="modalInfo = false">
                FERMER
              </b-button>
            </b-row>
          </b-col>
        </template>

        <div slot="modal-title">
          <b-icon-info-circle-fill variant="primary" />
          Métadonnées
        </div>
        <h6>
          Cette fenêtre présente les métadonnées rattachées à votre document:
        </h6>
        <br />
        <b-table :fields="fieldsMeta" :items="modalItems"></b-table>
      </b-modal>
    </b-modal>

    <!--        BOUTON PLUGIN-->
    <b-button @click="openModal" variant="info">{{ tag }}</b-button>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BTable,
  BFormInput,
  BBadge,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BIconFolderFill,
  BIconInfoCircleFill,
  BIconSubtract,
  BIcon,
} from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { documentService } from "../services/documentService";
import { ssoService } from "../services/ssoService";

var moment = require("moment");

export default {
  name: "cnaDocs",
  props: {
    tag: { type: String, default: "PLUGIN DNA" },
    tokenAccess: { type: String, default: "" },
    baseApiUrl: { type: String, default:"http://localhost:8080" },
    modeDoc: { type: String, default: "CONSULTATION_DOCUMENT" },
  },
  mixins: [documentService, ssoService],
  components: {
    BButton,
    BModal,
    BTable,
    BFormInput,
    BBadge,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BIconFolderFill,
    BIconInfoCircleFill,
    BIconSubtract,
    BIcon,
  },
  data() {
    return {
      modalShow: false,
      modalInfo: false,
      modalItems: [],
      authentifie: false,
      popUpConnexionInInterval: false,
      documents: [],
      selected: [],
      link: "",
      fieldsDocs: [
        { label: "Nom", key: "nom", sortable: true },
        { label: "Type", key: "type", sortable: true },
        { label: "Date d'expiration", key: "expirationDate", sortable: true },
        { label: "Statut", key: "status", sortable: true },
        { label: "Metadonnées", key: "metaDonnees", sortable: false },
      ],
      fieldsMeta: [
        { label: "Label", key: "cle" },
        { label: "Valeur", key: "valeur" },
      ],
    };
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
      this.link = "";
    },
    openModalInfo(item) {
      this.modalItems = item;
      this.modalInfo = true;
    },
    formatDate(date) {
      moment.locale("fr");
      if (date == null) return "N/A";
      else return moment(date).format("DD/MM/YYYY");
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    },
    serviceGetLink() {
      this.getLink(this.selected[0].id, this.baseApiUrl, this.tokenaccess).then(
        (r) => {
          this.link = r.data;
        }
      );
    },
    openModal() {
      if (this.authentifie == true) {
        this.getAllDocuments(this.baseApiUrl, this.tokenaccess).then((r) => {
          this.documents = r.data;
        });
        this.modalShow = true;
        return;
      }
    },
  },
  mounted() { },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
};
</script>

<style scoped>
.fontStyle {
  font-family: Roboto, sans-serif;
}
</style>