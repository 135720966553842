import axios from "axios";

export const ssoService = {

    methods: {

        ping(urlCNA) {
          
            const endpoint = `${urlCNA}/api/v1/ping`;
            return axios.get(endpoint);
        }
        ,
        async checkSSO(urlCNA) {
            const endpoint = `${urlCNA}/api/authenticate`;
            return axios.get(endpoint, {withCredentials: true});
        }
        ,
        openSSOWindow(urlCNA) {
            const endpoint = `${urlCNA}/sso/sso-noclosing.html`;

            const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
            const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

            const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
            const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

            const systemZoom = width / window.screen.availWidth;
            const left = (width - 360) / 2 / systemZoom + dualScreenLeft;
            const top = (height - 401) / 2 / systemZoom + dualScreenTop;
            return window.open(
                endpoint,
                "_blank",
                "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=360,height=401,left=" + left + ",top=" + top
            );
        }
        ,
        openSSOTab(urlCNA) {
            window.open(`${urlCNA}/sso/sso-noclosing.html`, "_top");
            // window.location.href = `${urlCNA}/api/authenticate`
        }
        ,
        keepSSO() {
            //setTimeout(this.checkSSO,2000);
            this.checkSSO().subscribe(data => {
                if (data == '401') {
                    this.openSSOWindow();
                } else {
                    this.log("Connecté")
                }
            });
        }
    }
}