<template>
  <div v-if="authentifie">
    <cna-docs v-if="mode==='CONSULTATION_DOCUMENT'" :tag="tag" :baseApiUrl="baseApiUrl" :mode="mode">
    </cna-docs>
    <cna-category v-if="mode==='CATEGORY_DOCUMENT'"
                  :tag="tag"  :baseApiUrl="baseApiUrl"
                  :mode="mode"
                  :nomDocument="nomDocument"
                  v-on:document="emitDocument" >
      <slot></slot>doc
<!--      <a :href="urlCNA + '/api/authenticate'">auth</a>-->
    </cna-category>
  </div>
  <div v-else @click="authentification" ref="clickauth">
    <slot></slot>auth
  </div>
</template>

<script>

  import cnaDocs from "@/components/cnaDocs";
  import cnaCategory from "@/components/cnaCategory";
  import Vue from 'vue';
  import { ssoService } from "./services/ssoService";
  import {categoryService} from "@/services/categoryService";


  export default {
    
  data () {
    return {
      eventBus: new Vue(),
      authentifie: false,
    }
  },
    name: 'App',
    props: {
     tag: {type: String, default: 'PLUGIN DNA'},
      baseApiUrl: {type: String, default: 'https://dna-eureka-intradef.defense-plateforme.com'},
      mode: {type: String, default: 'CATEGORY_DOCUMENT'},
      nomDocument: {type: String, default: 'JUSTIFICATIF_IDENTITE'}
    },
    mixins: [categoryService, ssoService],
    components: {
      cnaDocs,
      cnaCategory,
    },
    methods:{
      emitDocument(metaDonneesJustificatifIdentite){
        this.$emit('document', metaDonneesJustificatifIdentite);
        console.log('Evènement émit du plugin sur le justificatif identite : ', metaDonneesJustificatifIdentite);
      },
      authentification: function () {
        this.checkSSO(this.baseApiUrl)
            .then(() => {
              this.authentifie = true;
            })
            .catch((err) => {
              if (err.response.data.status == "401") {
                this.openSSOWindow(this.baseApiUrl);
              }
            });
        var tid = setInterval(() => {
          this.checkSSO(this.baseApiUrl)
              .then(() => {
                this.authentifie = true;
                clearInterval(tid);
              })
              .catch((err) => {
                if (err.response.data.status == "401") {
                  if (this.popUpConnexionInInterval == false) {
                    this.popUpConnexionInInterval = true;
                  }
                }
              });
        }, 10000);
      }
    },
    mounted () {  }
  }

</script>

<style lang="scss">

  @import "./../node_modules/bootstrap/dist/css/bootstrap.css";
  @import "./../node_modules/bootstrap-vue/dist/bootstrap-vue.css";

</style>
